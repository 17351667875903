import { graphql } from "gatsby"
import React from "react"
import Blogs from "../components/Blogs"
import Layout from "../components/Layout"

const BlogsPage = ({
  data: {
    allStrapiBlogs: { nodes: blogs },
  },
}) => {

  return (
    <Layout>
      <section className="blog-page">
        <Blogs blogs={blogs} title="blog" />
      </section>
    </Layout>
  )
}

export const query = graphql`
  {
    allStrapiBlogs {
      nodes {
        category
        date(formatString: "MMMM DD, YYYY")
        description
        id
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
        slug
        title
      }
    }
  }
`

export default BlogsPage
